import React, { useState } from "react";
import { Cut, Printer, Text, render } from "react-thermal-printer";

const Receipt = () => {
  const [data, setData] = useState(null);

  const renderReceipt = async () => {
    const receipt = (
      <Printer type="epson" width={42}>
        <Text size={2} height={2}>
          Original Receipt
        </Text>
        <Cut />
      </Printer>
    );

    const data = await render(receipt);
    setData(data);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-2">
      <button className="btn btn-primary m-1" onClick={renderReceipt}>
        Print Receipt
      </button>
      {data && <pre>{data.toString()}</pre>}
    </div>
  );
};

export default Receipt;
