import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ThermalPrint from "./components/ThermalPrint";
import Receipt from "./components/Receipt";

function App() {

  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            {/* <Route path="/" element={<ThermalPrint />}></Route> */}
            <Route path="/" element={<Receipt />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
